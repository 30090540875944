<template>
  <div>
    <v-card class="d-flex">
      <v-sheet style="width:100%">
        <v-card-title>
          定期便編集
          <v-spacer></v-spacer>
        </v-card-title>
        <template>
          <v-spacer></v-spacer>
          <template v-if="apierror.status == 'error'">
            <div v-for="msg in apierror.messages" :key="msg">
              <v-row class="ml-6 mb-3 ma-3">
                <span style="color: red">* {{ msg }} </span>
              </v-row>
            </div>
          </template>
          <template>
            <v-divider></v-divider>
            <!-- <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="12"
                md="2"
              >
                <label style="font-size:16px;color:#636363">コース・年・シーズン</label><span style="color:red">*</span>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="py-0 my-0"
              >
                <v-autocomplete
                  v-model="subsForm.subs_course"

                  :items="sellYear"
                  readonly
                  placeholder="コース"
                  color="#fa7a00"
                  outlined
                  dense
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="py-0 my-0"
              >
                <v-autocomplete
                  v-model="subsForm.subs_year"
                  :error-messages="subsYearErrors"
                  :items="sellYear"
                  readonly
                  placeholder="年"
                  color="#fa7a00"
                  outlined
                  dense
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="py-0 my-0"
              >
                <v-autocomplete
                  v-model="subsForm.subs_season"
                  :error-messages="subsSeasonErrors"
                  :items="sellSeason"
                  readonly
                  placeholder="シーズン"
                  color="#fa7a00"
                  outlined
                  dense
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
            </v-row> -->
            <v-row class="px-2 ma-0 my-2">
              <v-col
                cols="12"
                md="2"
              >
                <span>定期便ID</span><span style="color:red">*</span>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="py-0 my-0"
                align-self="center"
              >
                <span style="font-weight:bold;">{{ selectedSubs.subs_id }} </span>
              </v-col>
            </v-row>
            <v-row class="px-2 ma-0 my-2">
              <v-col
                cols="12"
                md="2"
              >
                <span>定期便コード</span><span style="color:red">*</span>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="py-0 my-0"
                align-self="center"
              >
                <!-- <v-text-field
                  v-model="$v.subsForm.subs_code.$model"
                  :error-messages="subsCodeErrors"
                  placeholder="定期便コード"
                  :counter="13"
                  hide-details="auto"
                  color="#fa7a00"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col> -->
                <span style="font-weight:bold;">{{ selectedSubs.subs_code }} </span>
              </v-col>
            </v-row>
            <v-row class="px-2 ma-0 mb-2">
              <v-col
                cols="12"
                md="2"
              >
                <span>定期便名</span><span style="color:red">*</span>
              </v-col>
              <v-col
                cols="12"
                md="7"
                class="py-0 my-0"
              >
                <v-text-field
                  v-model="$v.subsForm.subs_name.$model"
                  :error-messages="subsNameErrors"
                  :counter="100"
                  dense
                  color="#fa7a00"
                  placeholder="定期便名"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 mb-2">
              <v-col
                cols="12"
                md="2"
              >
                <label style="font-size:16px;color:#636363">販売可否</label><span style="color: red">*</span>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="py-0 my-0"
              >
                <v-select
                  v-model="subsForm.saleable"
                  :items="sellStatus"
                  placeholder="販売可否"
                  color="#fa7a00"
                  outlined
                  dense
                  hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>
            <!-- <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="12"
                md="2"
              >
                <label style="font-size:16px;color:#636363">販売価格(税抜き)</label>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="py-0 my-0"
              >
                <NumberInput
                  v-model="$v.subsForm.subs_tep.$model"
                  :error-messages="subsTepErrors"
                  :outlined="true"
                  prefix="¥"
                ></NumberInput>
              </v-col>
            </v-row> -->
            <!-- <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="12"
                md="2"
              >
                <label style="font-size:16px;color:#636363">消費税</label>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="py-0 my-0"
              >
                <v-autocomplete
                  v-model="subsForm.subs_ctr"
                  :error-messages="subsCtrErrors"
                  color="#fa7a00"
                  :items="taxs"
                  :value="taxs"
                  suffix="%"
                  outlined
                  dense
                  placeholder="消費税"
                  hide-details="auto"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="py-0 my-0"
              >
              </v-col>
              <p class="pb-0 mb-0" style="color:#fa7a00;font-size: small;">
                {{ refValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}
              </p>
              <p class="pb-0 mb-0 mr-3" style="font-size: normal;">
                (入力値税込価格)
              </p>
              <p class="pb-0 mb-0" style="color:#fa7a00;font-size: small;">
                {{ taxValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}
              </p>
              <p class="pb-0 mb-0" style="font-size: normal;">
                (税金)
              </p>
            </v-row> -->
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="12"
                md="2"
              >
                <label style="font-size:16px;color:#636363">販売価格(税込み)</label><span style="color: red">*</span>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="py-0 my-0"
              >
                <NumberInput
                  v-model="$v.subsForm.subs_tip.$model"
                  :error-messages="subsTipErrors"
                  :outlined="true"
                  prefix="¥"
                ></NumberInput>
              </v-col>
              <v-col
                cols="12"
                md="1"
                class="py-0 my-0"
              >
              </v-col>
              <!-- <p v-if="gapValueOn" class="pb-0 mb-0" style="color:#FF0000;font-size: small;">
                {{ gapValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}
              </p>
              <p v-if="gapValueOn" class="pb-0 mb-0" style="font-size: normal;">
                (計算差額)
              </p> -->
            </v-row>
            <!-- <v-row class="px-2 ma-0 my-2">
              <v-col cols="12" md="2">
                <span style="font-size:16px;color:#636363">内容量</span>
              </v-col>
              <v-col cols="12" md="3" class="py-0 my-0">
                <NumberInput
                  v-model="subsForm.subs_weight"
                  :outlined="true"
                  :error-messages="subsWeightErrors"
                  suffix="g"
                ></NumberInput>
              </v-col>
            </v-row> -->
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="12"
                md="2"
              >
                <label style="font-size:16px;color:#636363">販売開始日</label><span style="color: red">*</span>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="py-0 my-0"
              >
                <v-menu
                  v-model="dateStartMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="subsForm.subs_ssd"
                      :error-messages="subsSsdErrors"
                      placeholder="販売開始日"
                      color="#fa7a00"
                      dense
                      hide-details="auto"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="subsForm.subs_ssd"
                    color="#fa7a00"
                    @input="dateStartMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="12"
                md="2"
              >
                <label style="font-size:16px;color:#636363">販売終了日</label>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="py-0 my-0"
              >
                <v-menu
                  v-model="dateEndMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="subsForm.subs_sed"
                      :error-messages="subsSedErrors"
                      placeholder="販売終了日"
                      color="#fa7a00"
                      dense
                      hide-details="auto"
                      outlined
                      v-bind="attrs"
                      clearable
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="subsForm.subs_sed"
                    color="#fa7a00"
                    :allowed-dates="allowedDates"
                    @input="dateEndMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row align="center" class="px-2 ma-0 mb-2">
              <v-col
                cols="12"
                md="2"
              >
                <label style="font-size:16px;color:#636363">間もなく発売</label><span style="color: red">*</span>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="py-0 my-0"
              >
                <v-select
                  v-model="subsForm.subs_cs_show"
                  :items="commingSoon"
                  placeholder="間もなく発売"
                  color="#fa7a00"
                  outlined
                  dense
                  hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>
            <!-- <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="12"
                md="2"
              >
                <label style="font-size:16px;color:#636363">販売終了</label>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="py-0 my-0"
              >
                <v-autocomplete
                  v-model="subsForm.subs_eos"
                  :items="sellOver"
                  placeholder="販売終了"
                  color="#fa7a00"
                  outlined
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row> -->

            <v-row align="center" class="px-2 ma-0 my-2">
              <v-col
                cols="12"
                md="2"
              >
                <label style="font-size:16px;color:#636363">荷作り</label>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="py-0 my-0"
              >
                <NumberInput
                  v-model="$v.subsForm.s60_max_qty.$model"
                  :error-messages="s60MaxQtyErrors"
                  :label="`60サイズ`"
                  :outlined="true"
                ></NumberInput>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="py-0 my-0"
              >
                <NumberInput
                  v-model="subsForm.s80_max_qty"
                  :error-messages="s80MaxQtyErrors"
                  :label="`80サイズ`"
                  :outlined="true"
                ></NumberInput>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="py-0 my-0"
              >
                <NumberInput
                  v-model="subsForm.s100_max_qty"
                  :error-messages="s100MaxQtyErrors"
                  :label="`100サイズ`"
                  :outlined="true"
                ></NumberInput>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row align="center" class="px-2 ma-0 my-1">
              <v-col
                cols="12"
                md="2"
              >
                <span>定期便中身</span>
              </v-col>
              <v-col
                class="pt-0"
                cols="12"
                md="7"
              >
                <v-sheet
                  width="100%"
                  min-height="240"
                  outlined
                  class="d-flex align-start flex-column"
                >
                  <div class="d-flex justify-end px-4 py-1" style="width:100%;">
                    <ProList
                      class="ml-auto"
                      :old-list="subsForm.subs_details"
                      :error-messages="subsDetailsErrors"
                      :show-btn="true"
                      @add_mainAllList="getaddMainAllList"
                    ></ProList>
                  </div>
                  <v-divider style="width:100%;"></v-divider>
                  <v-list
                    style="width:100%;flex:1;"
                  >
                    <draggable
                      v-model="subsForm.subs_details"
                      style="height:100%"
                      :group="groupSet"
                      animation="300"
                      @end="onEnd"
                    >
                      <v-list-item
                        v-for="(item, i) in subsForm.subs_details"
                        :key="i"
                        style="height:14px; min-height:0;"
                        class="my-3"
                      >
                        <v-list-item-action class="my-0 mr-0 py-0">
                          <v-chip
                            style="font-size:1px;height:20px; min-height:0;width: 100%;"
                            :color="getColor(item)"
                            class="d-flex justify-space-between"
                            @click.stop
                          >
                            <v-icon
                              size="16"
                              color="red"
                              class="mx-1"
                              @click="outFrList(item)"
                            >
                              {{ icons.mdiCloseCircleOutline }}
                            </v-icon>
                            <span
                              style="font-size:1px; color:black;width: 1000px; overflow: hidden; text-overflow:ellipsis;"
                              class="px-5"
                              :title="item.item_name"
                            >
                              {{ item.item_name }}
                            </span>
                            <v-icon
                              size="16"
                              color="black"
                              class="mx-1"
                              @click="decreaseQty(item)"
                            >
                              {{ icons.mdiMinusBoxOutline }}
                            </v-icon>
                            <span style="color:black;width:20px;text-align:center">{{ item.item_qty }}</span>
                            <v-icon
                              size="16"
                              color="black"
                              class="mx-1"
                              @click="increaseQty(item)"
                            >
                              {{ icons.mdiPlusBoxOutline }}
                            </v-icon>
                          </v-chip>
                        </v-list-item-action>
                      </v-list-item>
                    </draggable>
                  </v-list>
                  <v-divider v-if="sumQuantity > 0" style="width:100%;"></v-divider>
                  <div v-if="sumQuantity > 0" class="d-flex justify-space-between px-4 py-1" style="width:100%;">
                    <!-- <span class="ml-4">
                      合計
                    </span> -->
                    <span class="mr-8">
                      品数：{{ sumQuantity }}
                    </span>
                  </div>
                </v-sheet>
                <!-- <span v-if="detailsErrors" style="color:red;font-size:smaller;text-align:center">
                  中身に商品を設定して下さい。
                </span> -->
              </v-col>
            </v-row>
            <v-row class="px-2 ma-0 my-2">
              <v-col
                id="showStyle"
                cols="12"
                md="2"
              >
                <span>表示レイアウト</span>
              </v-col>
              <v-col
                cols="12"
                md="4"
                class="py-0 my-0"
              >
                <v-text-field
                  v-model="$v.subsForm.show_style.$model"
                  :error-messages="showStyleErrors"
                  placeholder="表示レイアウト"
                  color="#fa7a00"
                  style="border-color:#ff4c51"
                  hide-details="auto"
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col>
                <span v-if="sum_showStyle > 0"> 合計: {{ sum_showStyle }}</span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-card-title style="font-size:16px">
              イメージ
            </v-card-title>
            <v-divider></v-divider>
            <v-row v-if="files.length !== 0" class="ma-0">
              <draggable
                v-model="files"
                class="d-flex flex-row flex-wrap"
                style="height:100%;width:100%"
                animation="300"
              >
                <v-col
                  v-for="(item, i) in files"
                  :key="i"
                  cols="2"
                  @contextmenu.prevent="openMenu($event,i)"
                >
                  <v-card
                    style="border: 1px solid #CFD8DC"
                    flat
                    tile
                    outlined
                    color="#fafafa"
                    height="100%"
                  >
                    <v-img
                      :src="`${subsForm.imgUrl_prefix ? subsForm.imgUrl_prefix : ''}/${item.replace(/^\//g, '')}`"
                      height="120px"
                      contain
                    >
                    </v-img>
                    <!-- <v-card-actions class="d-flex justify-center pb-2 pt-2" style="background-color:#ddd;flex:1">
                <span style="font-size: 14px;">{{ item.name }}</span>
              </v-card-actions> -->
                  </v-card>
                </v-col>
              </draggable>
            </v-row>
            <div v-else>
              <div class="d-flex justify-center align-center my-4">
                <v-img
                  src="@/assets/images/noimage.jpg"
                  max-width="120"
                >
                </v-img>
              </div>
              <div class="d-flex justify-center align-center my-4">
                <span>[ + ] ボタンを使用して、さらにイメージを追加します。</span>
              </div>
            </div>
            <v-card-actions class="py-3">
              <AddMedia
                :show-btn="false"
                @exportFile="insertImgs"
              ></AddMedia>
            </v-card-actions>

            <v-divider></v-divider>
            <v-row no-gutters class="px-2 ma-3 my-4">
              <v-col
                cols="12"
                md="12"
              >
                <span style="font-size:16px;color:black">商品概要</span>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <div class="d-flex align-center flex-wrap">
                  <v-btn
                    color="#4682B4"
                    elevation="0"
                    class="mb-4 me-3 mt-2"
                    @click="onOverviewEditor"
                  >
                    <span style="color:white">編集の表示/非表示</span>
                  </v-btn>

                  <AddMedia
                    :show-btn="true"
                    @exportFile="insertSubsOverview"
                  ></AddMedia>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <ckeditor
                  v-if="showOverviewEditor"
                  v-model="subsForm.subs_overview"
                  :editor="editor"
                  :config="editorConfig"
                ></ckeditor>
                <v-textarea
                  v-else
                  v-model="$v.subsForm.subs_overview.$model"
                  :error-messages="overviewErrors"
                  name="note1"
                  hide-details="auto"
                  outlined
                  :counter="5000"
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-2 ma-3 my-4">
              <v-col
                cols="12"
                md="12"
              >
                <span style="font-size:16px;color:black">商品説明</span>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <div class="d-flex align-center flex-wrap">
                  <v-btn
                    color="#4682B4"
                    elevation="0"
                    class="mb-4 me-3 mt-2"
                    @click="onDescEditor"
                  >
                    <span style="color:white">編集の表示/非表示</span>
                  </v-btn>

                  <AddMedia
                    :show-btn="true"
                    @exportFile="insertSubsDesc"
                  ></AddMedia>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <ckeditor
                  v-if="showDescEditor"
                  v-model="subsForm.subs_desc"
                  :editor="editor"
                  :config="editorConfig"
                ></ckeditor>
                <v-textarea
                  v-else
                  v-model="$v.subsForm.subs_desc.$model"
                  :error-messages="descErrors"
                  name="note2"
                  hide-details="auto"
                  outlined
                  :counter="5000"
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <!-- <v-row class="px-2 ma-0 my-2">
              <v-col
                cols="12"
                md="2"
                class="py-0 my-1"
              >
                <label style="font-size:16px;color:#636363">メタタグキーワード</label>
              </v-col>
              <v-col
                cols="12"
                md="9"
                class="py-0 my-1"
              >
                <v-textarea
                  v-model="$v.subsForm.subs_meta_words.$model"
                  :error-messages="subsMetaWordsErrors"
                  color="#fa7a00"
                  outlined
                  dense
                  placeholder="北海道"
                  rows="5"
                  :counter="200"
                  hide-details="auto"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider></v-divider> -->
            <v-card-actions align="center" class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mr-2"
                elevation="0"
                :loading="submitStatus"
                @click="submit(subsForm)"
              >
                <span style="color:white">保存</span>
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                elevation="0"
                @click="cancelClick"
              >
                <span>キャンセル</span>
              </v-btn>
            </v-card-actions>
          </template>
        </template>
      </v-sheet>
    </v-card>
    <media-edit-menu
      :visible="visible"
      :left="left"
      :top="top"
      @openConfirmDelete="deleteFile"
    ></media-edit-menu>
  </div>
</template>

<script>
import Editor from 'ckeditorplugin'
import {
  mdiMinusBoxOutline,
  mdiPlusBoxOutline,
  mdiCloseCircleOutline,
} from '@mdi/js'
import {
  required,
  maxLength,
  numeric,
  minValue,
} from 'vuelidate/lib/validators'
import {
  mapMutations,
  mapActions,
  mapState,
} from 'vuex'
import ProList from '@/components/ProList.vue'
import AddMedia from '@/components/AddMedia.vue'
import MediaEditMenu from '@/views/bimi/setting/Media/MediaEditMenu.vue'
import NumberInput from '@/components/Input/NumberInput.vue'

export default {
  components: {
    ProList,
    AddMedia,
    MediaEditMenu,
    NumberInput,
  },
  data: () => ({
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    groupSet: {
      name: 'foodSet',
      pull: true,
      put: true,
    },
    submitStatus: false,
    visible: false,
    top: 0,
    left: 0,
    rightClickItem: '',
    editor: Editor,
    editorData: '<p>Content of the editor.</p>',
    editorConfig: {
      // The configuration of the editor.
    },
    showOverviewEditor: true,
    showDescEditor: true,
    taxs: [8, 10],
    dateEndMenu: '',
    dateStartMenu: '',
    icons: {
      mdiMinusBoxOutline,
      mdiPlusBoxOutline,
      mdiCloseCircleOutline,
    },
    sellStatus: [
      {
        text: '販売不可', value: 0,
      },
      {
        text: '販売可', value: 1,
      },
    ],
    commingSoon: [
      {
        text: '非表示', value: 0,
      },
      {
        text: '表示', value: 1,
      },
    ],
    sellOver: [
      {
        text: 'YES', value: 1,
      },
      {
        text: 'NO', value: 9,
      },
    ],
    sellYear: [],
    sellSeason: [
      {
        text: 'S1', value: 1,
      },
      {
        text: 'S2', value: 2,
      },
      {
        text: 'S3', value: 3,
      },
      {
        text: 'S4', value: 4,
      },
    ],
    files: [],
    images: [],
    subsForm: {
      subs_id: '',
      subs_code: '',
      subs_name: '',
      saleable: '',
      subs_details: [],
      subs_images: '',
      subs_overview: '',
      subs_desc: '',
      subs_tep: '',
      subs_ctr: '',
      subs_tip: '',
      subs_weight: 0,
      subs_ssd: '',
      subs_sed: '',
      subs_season: '',
      subs_eos: '',
      show_style: '',

      // subs_meta_words: '',
      s60_max_qty: '',
      s80_max_qty: '',
      s100_max_qty: '',
      subs_cs_show: 0,
    },
    taxValue: 0,
    refValue: 0,
    gapValue: 0,
    gapValueOn: false,
    subsSsdFlag: true,
    dateNum: '',
    thisYear: '',
    nextYear: '',
    sum_showStyle: 0,

    // detailsErrors: false,
  }),
  validations: {
    subsForm: {
      subs_name: {
        required,
        maxLength: maxLength(100),
      },
      subs_code: {
        required,
        maxLength: maxLength(13),
      },
      saleable: {
        required,
      },
      subs_year: {
        required,
      },
      subs_season: {
        required,
      },
      subs_images: {
        maxLength: maxLength(2000),
      },
      subs_overview: {
        // required,
        maxLength: maxLength(5000),
      },
      subs_desc: {
        // required,
        maxLength: maxLength(5000),
      },
      subs_tep: {
        // required,
        numeric,

        // minValue: minValue(1),
      },
      subs_ctr: {
        // required,
        numeric,
      },
      subs_tip: {
        required,
        numeric,
        minValue: minValue(1),
      },
      show_style: {
        numeric,
      },
      subs_weight: {
        // required,
        numeric,

        // minValue: minValue(1),
      },
      subs_ssd: {
        required,
      },
      subs_sed: {
        required,
      },

      // subs_meta_words: {
      //   maxLength: maxLength(200),
      // },
      s60_max_qty: {
        required,
        numeric,
      },
      s80_max_qty: {
        required,
        numeric,
        minValue: minValue(1),
      },
      s100_max_qty: {
        required,
        numeric,
        minValue: minValue(1),
      },
      subs_details: {
        $each: {
          item_qty: {
            required,
          },
          item_code: {
            required,
          },
          item_disp_order: {
            required,
          },
        },
      },
    },
  },
  computed: {
    ...mapState('subscriptionStore', ['selectedSubs']),
    sumQuantity() {
      // let sum = 0
      // this.subsForm.subs_details.forEach(sd => {
      //   if (sd.item_code) {
      //     sum += sd.item_qty
      //   }
      // })

      return this.subsForm.subs_details.length
    },
    getColor() {
      return function (statusColor) {
        let color = '#ffcda3'
        if (statusColor.type === 'main') {
          color = '#FDF5E6'
        }
        if (statusColor.type === 'sup') {
          color = '#FAF0E6'
        }
        if (statusColor.type === 'soup') {
          color = '#FAEBD7'
        }
        if (statusColor.type === 'lunch') {
          color = '#FFEFD5'
        }
        if (statusColor.type === 'dessert') {
          color = '#FFEBCD'
        }
        if (statusColor.type === 'other') {
          color = '#FFE4C4'
        }

        return color
      }
    },
    s60MaxQtyErrors() {
      const errors = []
      if (!this.$v.subsForm.s60_max_qty.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.s60_max_qty.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.s60_max_qty.numeric && errors.push('必須数字')

      return errors
    },
    s80MaxQtyErrors() {
      const errors = []
      if (!this.$v.subsForm.s80_max_qty.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.s80_max_qty.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.s80_max_qty.numeric && errors.push('必須数字')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.s80_max_qty.minValue && errors.push('荷作り(80サイズ)は0以外にしてください')

      return errors
    },
    s100MaxQtyErrors() {
      const errors = []
      if (!this.$v.subsForm.s100_max_qty.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.s100_max_qty.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.s100_max_qty.numeric && errors.push('必須数字')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.s100_max_qty.minValue && errors.push('荷作り(100サイズ)は0以外にしてください')

      return errors
    },

    subsNameErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_name.$dirty) return errors

      console.log('222', this.$v.subsForm.subs_name.required)
      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_name.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_name.maxLength && errors.push('100文字以内にしてください。')

      return errors
    },
    subsCodeErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_code.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_code.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_code.maxLength && errors.push('13文字以内にしてください。')

      return errors
    },

    saleableErrors() {
      const errors = []
      if (!this.$v.subsForm.saleable.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.saleable.required && errors.push('必須項目')

      return errors
    },

    subsYearErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_year.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_year.required && errors.push('必須項目')

      return errors
    },

    subsSeasonErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_season.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_season.required && errors.push('必須項目')

      return errors
    },

    subsImagesErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_images.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_images.maxLength && errors.push('3000文字以内にしてください。')

      return errors
    },

    overviewErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_overview.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.subsForm.subs_overview.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_overview.maxLength && errors.push('5000文字以内にしてください。')

      return errors
    },

    descErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_desc.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.subsForm.subs_desc.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_desc.maxLength && errors.push('5000文字以内にしてください。')

      return errors
    },

    // subsTepShowErrors() {
    //   const errors = []
    //   if (!this.$v.subsForm.subs_tep_show.$dirty) return errors

    //   // eslint-disable-next-line no-unused-expressions
    //   !this.$v.subsForm.subs_tep_show.required && errors.push('必須項目')

    //   return errors
    // },
    subsTepErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_tep.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.subsForm.subs_tep.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_tep.numeric && errors.push('必須数字')

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.subsForm.subs_tep.minValue && errors.push('販売価格(税抜き)は0以外にしてください')

      return errors
    },

    subsCtrErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_ctr.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.subsForm.subs_ctr.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_ctr.numeric && errors.push('必須数字')

      return errors
    },

    subsTipErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_tip.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_tip.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_tip.numeric && errors.push('必須数字')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_tip.minValue && errors.push('販売価格(税込み)は0以外にしてください')

      return errors
    },

    showStyleErrors() {
      const errors = []
      if (!this.$v.subsForm.show_style.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.show_style.numeric && errors.push('必須数字')

      return errors
    },

    subsWeightErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_weight.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.subsForm.subs_weight.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_weight.numeric && errors.push('必須数字')

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.subsForm.subs_weight.minValue && errors.push('販売価格(税込み)は0以外にしてください')

      return errors
    },

    subsSsdErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_ssd.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_ssd.required && errors.push('必須項目')

      return errors
    },

    subsSedErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_sed.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.subsForm.subs_sed.required && errors.push('販売開始日以後にしてください。')

      return errors
    },

    // subsMetaWordsErrors() {
    //   const errors = []
    //   if (!this.$v.subsForm.subs_meta_words.$dirty) return errors

    //   // eslint-disable-next-line no-unused-expressions
    //   !this.$v.subsForm.subs_meta_words.maxLength && errors.push('200文字以内にしてください。')

    //   return errors
    // },

    subsDetailsErrors() {
      const errors = []
      if (!this.$v.subsForm.subs_details.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.subsForm.subs_details.required && errors.push('必須項目')

      return errors
    },
  },
  watch: {
    'subsForm.show_style': {
      handler(value) {
        if (value) {
          let sum = 0
          value.split('').forEach(s => {
            sum += parseInt(s, 10)
          })

          this.sum_showStyle = sum
        }
      },
    },

    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },

    'subsForm.subs_ssd': {
      handler(newValue) {
        if (this.subsSsdFlag) {
          this.subsSsdFlag = false
        } else {
          const sedValue = new Date(Date.parse(newValue))

          // 3ヶ月後の最後の日
          // this.sedNumber = this.sedValue.setMonth(this.sedValue.getMonth() + 4)
          // this.sedNumber = new Date(this.sedNumber).toISOString().substring(0, 10)
          // this.sedNumber = new Date(Date.parse(this.sedNumber))
          // this.sedNumber = this.sedNumber.setDate(0)

          // 3ヶ月後
          const sedMonth = sedValue.setMonth(sedValue.getMonth() + 3)
          const sedDate = new Date(sedMonth).setDate(sedValue.getDate() - 1)

          // 89日後
          // this.sedNumber = this.sedValue.setDate(this.sedValue.getDate() + 89)
          this.subsForm.subs_sed = new Date(sedDate).toISOString().substring(0, 10)
        }
      },
    },

    // 'subsForm.subs_tep': {
    //   handler() {
    //     if (this.flag === true) {
    //       this.taxValue = (this.subsForm.subs_tep * (this.subsForm.subs_ctr / 100)).toFixed(2)
    //       if (Number(this.subsForm.subs_tep) !== this.selectedSubs.subs_tep) {
    //         console.log('true#')
    //         this.subsForm.subs_tip = Math.round(this.refValue)
    //         this.taxValue = (this.subsForm.subs_tep * (this.subsForm.subs_ctr / 100)).toFixed(2)
    //         this.refValue = (this.subsForm.subs_tep * ((100 + this.subsForm.subs_ctr) / 100)).toFixed(2)
    //         this.flag = false
    //       }
    //     } else if (this.flag === false) {
    //       this.refValue = (this.subsForm.subs_tep * ((100 + this.subsForm.subs_ctr) / 100)).toFixed(2)
    //       this.subsForm.subs_tip = Math.round(this.refValue)
    //       this.taxValue = (this.subsForm.subs_tep * (this.subsForm.subs_ctr / 100)).toFixed(2)
    //       this.gapValue = (this.subsForm.subs_tip - this.refValue).toFixed(2)
    //     }
    //   },
    // },
    // 'subsForm.subs_ctr': {
    //   handler() {
    //     if (this.flag === true) {
    //       this.refValue = (this.subsForm.subs_tep * ((100 + this.subsForm.subs_ctr) / 100)).toFixed(2)
    //       if (Number(this.subsForm.subs_ctr) !== this.selectedSubs.subs_ctr) {
    //         this.refValue = (this.subsForm.subs_tep * ((100 + this.subsForm.subs_ctr) / 100)).toFixed(2)
    //         this.taxValue = (this.subsForm.subs_tep * (this.subsForm.subs_ctr / 100)).toFixed(2)
    //         this.subsForm.subs_tip = Math.round(this.refValue)
    //         this.gapValue = (this.subsForm.subs_tip - this.refValue).toFixed(2)
    //         this.flag = false
    //       }
    //     } else if (this.flag === false) {
    //       this.refValue = (this.subsForm.subs_tep * ((100 + this.subsForm.subs_ctr) / 100)).toFixed(2)
    //       this.taxValue = (this.subsForm.subs_tep * (this.subsForm.subs_ctr / 100)).toFixed(2)
    //       this.subsForm.subs_tip = Math.round(this.refValue)
    //       this.gapValue = (this.subsForm.subs_tip - this.refValue).toFixed(2)
    //     }
    //   },
    // },
    // 'subsForm.subs_tip': {
    //   handler() {
    //     if (this.gapValue !== 0) {
    //       this.gapValueOn = true
    //     } else {
    //       this.gapValueOn = false
    //     }
    //     this.gapValue = (this.subsForm.subs_tip - this.refValue).toFixed(2)
    //     if (this.flag === true) {
    //       if (Number(this.subsForm.subs_tip) !== this.selectedSubs.subs_tip) {
    //         this.gapValue = (this.subsForm.subs_tip - this.refValue).toFixed(2)
    //         this.flag = false
    //       }
    //     } else if (this.flag === false) {
    //       this.gapValue = (this.subsForm.subs_tip - this.refValue).toFixed(2)
    //     }
    //   },
    // },

    // 'subsForm.subs_details': {
    //   handler() {
    //     if (this.subsForm.subs_details.length === 0) {
    //       this.detailsErrors = true
    //     } else {
    //       this.detailsErrors = false
    //     }
    //   },
    // },
    files: {
      handler(newValue) {
        this.subsForm.subs_images = ''
        newValue.forEach((n, i) => {
          if (i === newValue.length - 1) {
            this.subsForm.subs_images += `"${n}"`
          } else {
            this.subsForm.subs_images += `"${n}",`
          }
        })

        // console.log('subs_images', this.subsForm.subs_images)
      },
    },
  },
  created() {
    this.dateNum = new Date()
    this.thisYear = this.dateNum.getFullYear()
    this.lastYear = this.thisYear - 1
    this.nextYear = 1 + this.thisYear
    this.sellYear.push(this.lastYear)
    this.sellYear.push(this.thisYear)
    this.sellYear.push(this.nextYear)
    this.loadData()
  },

  destroyed() {
    this.clearSelectedSubs()
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('subscriptionStore', ['loadSubs', 'editSubs']),
    ...mapMutations('subscriptionStore', ['clearSelectedSubs']),

    cancelClick() {
      if (!this.$isObjectEqual(this.subsForm, this.selectedSubs)) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },

    allowedDates(val) {
      return Date.parse(val) > Date.parse(this.subsForm.subs_ssd)
    },
    loadData() {
      console.log('load,subs_id', this.$route.params.id)
      this.setOverlayStatus(true)
      this.loadSubs(this.$route.params.id).then(() => {
        // this.subsForm = {
        //   ...this.selectedSubs,
        // }
        this.subsForm = this.$deepCopy(this.selectedSubs)
        if (this.selectedSubs.subs_images) {
          this.selectedSubs.subs_images.split(',').forEach(i => {
            this.files.push(i.replace(/^"+|"$/g, ''))
          })
        }

        console.log('overview:', this.selectedSubs)
      }).catch(error => {
        if (error.response) {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    insertImgs(files) {
      if (files.length !== 0) {
        files.forEach(f => {
          this.files.push(f.url)
        })
        this.subsForm.imgUrl_prefix = files[0].full_url.replace(files[0].url, '').replace(/\/$/g, '')
      }
    },
    openMenu(e, index) {
      this.rightClickIndex = index
      console.log('openMenu', this.rightClickIndex)

      const navMenu = document.getElementsByClassName('app-navigation-menu')[0].style.transform
      if (navMenu === 'translateX(0%)') {
        this.left = e.pageX - 268
      } else {
        this.left = e.pageX
      }
      this.top = e.pageY - 74

      this.visible = true
    },

    closeMenu() {
      this.visible = false
    },

    deleteFile() {
      this.files.splice(this.rightClickIndex, 1)
    },
    insertSubsOverview(files) {
      let content = ''
      files.forEach(file => {
        content += `<img src="${file.full_url}" alt="${file.name}" /><br />`
      })
      this.subsForm.subs_overview += content
    },
    insertSubsDesc(files) {
      let content = ''
      files.forEach(file => {
        content += `<img src="${file.full_url}" alt="${file.name}" /><br />`
      })
      this.subsForm.subs_desc += content
    },
    onOverviewEditor() {
      this.showOverviewEditor = !this.showOverviewEditor
    },
    onDescEditor() {
      this.showDescEditor = !this.showDescEditor
    },

    getaddMainAllList(addMainAllList) {
      console.log('getaddMainAllList0', addMainAllList)

      this.subsForm.subs_details = addMainAllList.map((mal, i) => {
        const item = {
        }
        item.item_code = mal.item_code
        item.item_name = mal.item_name

        // item.item_up_value = mal.item_up_value
        item.item_qty = mal.item_qty ? mal.item_qty : 1

        // item.totalPrice = mal.item_up_value * (mal.item_qty ? mal.item_qty : 1)
        item.item_disp_order = i + 1

        return item
      })
      console.log('getaddMainAllList', this.subsForm.subs_details)
    },
    increaseQty(item) {
      console.log('increaseQty', item.item_code)
      const index = this.subsForm.subs_details.findIndex(se => se.item_code === item.item_code)
      if (index >= 0) {
        this.subsForm.subs_details[index].item_qty += 1
        console.log('Iqty', this.subsForm.subs_details[index].item_qty)
      }
    },
    decreaseQty(item) {
      const index = this.subsForm.subs_details.findIndex(se => se.item_code === item.item_code)
      if (index >= 0 && item.item_qty > 1) {
        this.subsForm.subs_details[index].item_qty -= 1
        console.log('Iqty', this.subsForm.subs_details[index].item_qty)
      }
    },
    outFrList(item) {
      const index = this.subsForm.subs_details.findIndex(se => se.item_code === item.item_code)
      if (index >= 0) this.subsForm.subs_details.splice(index, 1)
      // eslint-disable-next-line no-self-assign
      this.subsForm.subs_details = this.subsForm.subs_details
      console.log('this.subsForm.subs_details', this.subsForm.subs_details)
    },
    onEnd() {
      this.subsForm.subs_details = this.subsForm.subs_details.map((sd, i) => {
        const item = {
          ...sd,
        }
        item.item_disp_order = i + 1

        return item
      })
      console.log('draggle end', this.subsForm.subs_details)
    },
    jumpToListPage() {
      this.$router.push({
        path: '/subscription-list',
        query: this.$route.params.query ?? {
        },
      })
    },

    submitTo(subsForm) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        const submitForm = {
          ...subsForm,
        }

        this.editSubs(submitForm)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          })
          .finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!')

        window.scrollTo(0, 0)

        // if (this.subsForm.subs_details.length === 0) {
        //   this.detailsErrors = true
        // } else {
        //   this.detailsErrors = false
        // }
        this.apierror.messages = []
        this.apierror.status = 'error'
        this.apierror.messages.push('赤い部分の情報を修正してください')
        this.showDescEditor = false
        this.showOverviewEditor = false
      }
    },

    submit(subsForm) {
      if (this.subsForm.subs_details.length !== 0) {
        if (this.subsForm.show_style) {
          let sum = 0
          this.subsForm.show_style.split('').forEach(s => {
            sum += parseInt(s, 10)
          })
          if (sum !== this.sumQuantity) {
            // eslint-disable-next-line no-alert
            window.confirm('中身の品数が表示レイアウトの合計と一致していません。')
            document.getElementById('showStyle').scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })
          } else {
            this.submitTo(subsForm)
          }
        } else {
          window.confirm('表示レイアウトは入力してください')
          document.getElementById('showStyle').scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      } else if (!this.$isObjectEqual(subsForm, this.selectedSubs)) {
        console.log('changed')
        this.submitTo(subsForm)
      } else {
        console.log('no changed')
        this.jumpToListPage()
      }

      // console.log('subsForm:', subsForm)
      // if (window.confirm(`販売価格(税込み)\n入力値は ${Math.abs(this.subsForm.subs_tip)} 円, 計算値は ${Math.abs(this.refValue)} 円(差値は ${Math.abs(this.gapValue)} 円)\nよろしいですか?`)) {
      //   this.submitTo(subsForm)
      // } else {
      //   console.log(subsForm)
      // }
    },
  },
}
</script>
<style>
.ql-editor{
  min-height: 120px;
}
</style>
